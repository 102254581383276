if ($('#contact').length || $('#residentsSub').length) {
	// Google Recaptcha v3
	grecaptcha.ready(function () {
		$('form.mod-recaptcha').each(function () {
			var object = $(this);
			grecaptcha.execute('6LfQ-rYdAAAAAMElsGWTY9AdzY0D3z5MDVjMpOnz', { action: 'homepage', score: 0.1 }).then(function (token) {
				$('<input>').attr({
					type: 'hidden',
					name: 'g-recaptcha-response',
					value: token
				}).appendTo(object)
			});
		});
	});
}

var doc = document.documentElement;
var w = window;

var prevScroll = w.scrollY || doc.scrollTop;
var curScroll;
var direction = 0;
var prevDirection = 0;

var header = document.getElementById('site-header');

if ($('#banner').length > 0) {
	var banner = document.getElementById('banner');
}

var checkScroll = function () {

	/*
	** Find the direction of scroll
	** 0 - initial, 1 - up, 2 - down
	*/

	curScroll = w.scrollY || doc.scrollTop;
	if (curScroll > prevScroll) {
		//scrolled up
		direction = 2;
	}
	else if (curScroll < prevScroll) {
		//scrolled down
		direction = 1;
	}

	if (direction !== prevDirection) {
		toggleHeader(direction, curScroll);
	}

	prevScroll = curScroll;
};

var toggleHeader = function (direction, curScroll) {
	if (direction === 2 && curScroll > 78) {

		//replace 52 with the height of your header in px

		header.classList.add('hide');
		if ($('#banner').length > 0) {
			banner.classList.add('hide');
		}
		prevDirection = direction;
	}
	else if (direction === 1) {
		header.classList.remove('hide');
		if ($('#banner').length > 0) {
			banner.classList.remove('hide');
		}
		prevDirection = direction;
	}
};

window.addEventListener('scroll', checkScroll);

$(window).on('scroll', function () {
	var scroll = $(window).scrollTop();

	if (scroll > 150) {
		$('.header-main').addClass('mod-bg');
		$('.sub-logo .mod-black').removeClass('mod-hide');
		$('.sub-logo .mod-white').addClass('mod-hide');
	} else {
		$('.header-main').removeClass('mod-bg');
		$('.sub-logo .mod-black').addClass('mod-hide');
		$('.sub-logo .mod-white').removeClass('mod-hide');
	}
});

// Show filters on mobile
$('.sub-dropdown').click(function (e) {
	e.preventDefault();


	$(this).toggleClass('mod-active');
	$('ul.list-categories').toggleClass('mod-active');
});

//hide cookie policy
$(".section-cookies .btn-accept").on("click", function (e) {
	e.preventDefault();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 1,
		}
	}).done(function (response) {
		$('.section-cookies').addClass('mod-hide').removeClass('show');
	});
});

$(".section-cookies .btn-decline").on("click", function (ev) {
	ev.preventDefault();

	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 0,
		}
	}).done(function (response) {
		$('.section-cookies').addClass('mod-hide').removeClass('show');
	});
});


//hide cookie policy
$(".section-banner .sub-close").on("click", function (e) {
	e.preventDefault();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/banner-cookie/",
	}).done(function (response) {
		$('.section-banner').addClass('mod-hide').removeClass('show');
		$('.header-main').removeClass('mod-expand');
	});
});

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Ajax contact form processing
$('#contact form').submit(function (e) {
	e.preventDefault();

	var formTargetScroll = $('#contact form').offset().top - 70;

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/contact/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#contact form .form-element').removeClass('mod-filled');
			$('#contact form').hide().trigger('reset');

			// Show thank you message
			$('#contact .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#contact form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#contact form').fadeIn();
				$('#contact .sub-thanks').hide();
			}, 6000);

			gtag('event', 'Submitted', { 'event_category': 'contact_form_completions', 'event_label': 'contact_form_completions' });

		}
		else {
			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');

			$('#contact form .sub-errors').fadeIn().html(response);
		}
	});
});

if ($('#residentsSub form').length) {
	var formTargetScroll = $('#residentsSub form').offset().top - 70;
}

// Ajax contact form processing
$('#residentsSub form.mod-fault-form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/fault/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-fault-form .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-fault-form').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-fault-form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-fault-form').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-fault-form .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
	});
});

// Ajax contact form processing
$('#residentsSub form.mod-claim-form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/insuranceClaim/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-claim-form .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-claim-form').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-claim-form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-claim-form').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-claim-form .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
	});
});

// Ajax contact form processing
$('#residentsSub form.mod-key-form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/keyRequest/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-key-form .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-key-form').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-key-form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-key-form').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-key-form .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
	});
});

// Ajax contact form processing
$('#residentsSub form.mod-mor').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/mor/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-mor .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-mor').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-mor .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-mor').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-mor .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
	});
});


$('#residentsSub form.mod-address-update').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/updateDetails/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-address-update .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-address-update').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-address-update .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-address-update').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-address-update .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
	});
});

$('#residentsSub form.mod-paperless').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/goPaperless/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-paperless .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-paperless').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-paperless .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-paperless').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-paperless .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');

			if (!$('.sub-checkbox-wrap.required :checkbox:checked').length > 0) {
				return false;
			}

		}
	});
});

$('#residentsSub form.mod-paper-app').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/goPaperless/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-paper-app .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-paper-app').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-paper-app .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-paper-app').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-paper-app .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');

			if (!$('.sub-checkbox-wrap.required :checkbox:checked').length > 0) {
				return false;
			}

		}
	});
});


$('#residentsSub form.mod-name-update').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/updateName/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-name-update .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-name-update').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-name-update .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-name-update').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-name-update .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
	});
});

$('#residentsSub form.mod-direct-debit').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/directDebit/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-direct-debit .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-direct-debit').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-direct-debit .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-direct-debit').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-direct-debit .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
	});
});

$('#residentsSub form.mod-alteration').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/licenceAlteration/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-alteration .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-alteration').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-alteration .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-alteration').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-alteration .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
	});
});

$('#residentsSub form.mod-pet-consent').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/petConsent/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-pet-consent .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-pet-consent').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-pet-consent .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-pet-consent').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-pet-consent .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
	});
});

$('#residentsSub form.mod-sublet').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/licenceSublet/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-sublet .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-sublet').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-sublet .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-sublet').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-sublet .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
	});
});

$('#residentsSub form.mod-compliment').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/compliment/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-compliment .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-compliment').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-compliment .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-compliment').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-compliment .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
	});
});

$('#residentsSub form.mod-vulnerable').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/vulnerablePersons/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#residentsSub form.mod-vulnerable .form-element').removeClass('mod-filled');
			$('#residentsSub form.mod-vulnerable').hide().trigger('reset');

			// Show thank you message
			$('#residentsSub .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#residentsSub form.mod-vulnerable .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#residentsSub form.mod-vulnerable').fadeIn();
				$('#residentsSub .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
		else {
			$('#residentsSub form.mod-vulnerable .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: formTargetScroll }, 1000, 'swing');
		}
	});
});

// Add class to inputs when clicked on add effect

if ($('form').length) {
	$('form input, form textarea').focus(function () {
		$(this).parent().addClass('mod-filled');

	}).blur(function () {
		if ($(this).val()) {
			$(this).parent().addClass('mod-filled');
		} else {
			$(this).parent().removeClass('mod-filled');
		}
	});
}


// Google Maps

// If div with ID of map exists then run the function
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [['Respair', lat, long, 1]];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[{ "featureType": "landscape", "stylers": [{ "saturation": -100 }, { "lightness": 60 }] }, { "featureType": "road.local", "stylers": [{ "saturation": -100 }, { "lightness": 40 }, { "visibility": "on" }] }, { "featureType": "transit", "stylers": [{ "saturation": -100 }, { "visibility": "simplified" }] }, { "featureType": "administrative.province", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "stylers": [{ "visibility": "on" }, { "lightness": 30 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ef8c25" }, { "lightness": 40 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi.park", "elementType": "geometry.fill", "stylers": [{ "color": "#b6c54c" }, { "lightness": 40 }, { "saturation": -40 }] }, {}],
			{ name: 'Styled Map' }
		);
	}

	// If the contact page then create the map with the following coordiantes
	if ($('#contact').length) {
		createMap('map', 51.570316, -0.339984, 9, true);
	}
}


/**
 * Scroll down 1 section when clicking arrow on home arrow
 */

$('.sub-arrow').on('click', function (ev) {
	ev.preventDefault();

	var link = $(this).attr('href').split('#').pop();
	var targetScroll = $('#' + link).offset().top;

	$('html, body').animate({ scrollTop: targetScroll }, 700, 'swing');
});

/**
 * Homepage hero scroll effect on text
 */

$(window).on('scroll load', function () {
	const hero = $('.section-hero.mod-parallax .sub-content'),
		contactHero = $('#contact .section-hero .sub-copy');

	// Parallax effect on homepage hero
	hero.css({
		"top": Math.max(50 - 0.02 * window.scrollY, 0) + "%",
		"opacity": 1 - $(window).scrollTop() / 450
	});

	// Parallax effect on contact hero
	contactHero.css({
		"top": Math.max(40 - 0.02 * window.scrollY, 0) + "%",
		"opacity": 1 - $(window).scrollTop() / 450
	});
});

/**
 * Custom Cursor Effects
 */

// if ($('#newsSection').length) {
// 	$(window).on('load resize', function () {
// 		if ($(window).width() > 768) {
// 			var newsArea = document.getElementsByClassName("mod-cursor"),
// 				cursor = document.getElementById("cursor"),
// 				newsLink = document.getElementsByClassName("mod-small-cursor");


// 			// Large purple cursor on News Section hover
// 			Array.from(newsArea).forEach(v => v.addEventListener("mousemove", function (e) {
// 				cursor.classList.add('mod-show');
// 				cursor.style.left = e.clientX + "px",
// 					cursor.style.top = e.clientY + "px";
// 			}));

// 			// Hide large purple cursor when leaving News Section
// 			Array.from(newsArea).forEach(v => v.addEventListener("mouseout", function (e) {
// 				cursor.classList.remove('mod-show');
// 			}));

// 			// Change to smaller alternative on link hovers
// 			Array.from(newsLink).forEach(v => v.addEventListener("mousemove", function (e) {
// 				cursor.classList.add('mod-alt');
// 				cursor.style.left = e.clientX + "px",
// 					cursor.style.top = e.clientY + "px";
// 			}));

// 			// Remove smaller alternative off link hovers
// 			Array.from(newsLink).forEach(v => v.addEventListener("mouseout", function (e) {
// 				cursor.classList.remove('mod-alt');
// 			}));
// 		};
// 	});
// }


/**
 * Hamburger Activation for Nav
 */

$(window).on('load', function () {
	setTimeout(function () {
		$('.footer-main').removeClass('mod-hide');
	}, 1000);
});

$('.mob-hamburger').click(function (e) {
	e.preventDefault();

	// Add active class to hamburger so it can be animated
	$(this).toggleClass('mod-active');

	// Add active class to nav to show
	$('#nav').removeClass('mod-hide');

	// Add no scroll class to body to prevent scrolling when nav is active
	$('body').toggleClass('no-scroll');

	// Add active class to header
	$('.header-main').toggleClass('mod-active');

	// Add active class on all li's
	if (!$(this).hasClass('mod-active')) {
		$('#nav ul li').addClass('mod-hide');
		setTimeout(function () {
			$('#nav').addClass('mod-hide');
		}, 550);
	} else {
		setTimeout(function () {
			$('#nav ul li').removeClass('mod-hide');
		}, 900);
	}
});

$('.list-nav li.mod-dropdown > a').click(function (e) {
	e.preventDefault();

	$(this).next('ul.sub-sub-menu').toggleClass('mod-active');
});


/**
 * Help page form jQuery
 */

if ($('#residentsSub .section-large-form, #residentsSub .sub-compliment-form, #career-application .section-application-form').length) {

	$("#attachment").change(function () {
		$(".file-upload").addClass('file-up');
		$("#upload-file").val("Your file has been added");
	});

	$(".attachment").change(function () {
		$(this).parent(".file-upload").addClass('file-up');
		$(this).parent(".file-upload").prev('.upload-file').val("Your file has been added");
	});
}

/**
 * Change style of agreement checkbox on forms
 */

$('input[type="checkbox"]').on('change', function () {

	$('.sub-checkbox-wrap').removeClass('mod-checked');

	if ($(this).is(":checked")) {
		$(this).parents('label').addClass('mod-checked');
		$(this).parents('.sub-checkbox-wrap').addClass('mod-checked');
	}
});

$('input[type="radio"]').on('change', function () {
	$(this).parents('.sub-checkbox-parent').find('.mod-checked').removeClass('mod-checked');

	if ($(this).is(":checked")) {
		$(this).parents('label').addClass('mod-checked');
		$(this).parents('.sub-checkbox-wrap').addClass('mod-checked');
	}
});

/**
 * Slick Carousel for news items on homepage for mobile only
 */

$('.section-news .list-news').slick({
	arrows: false,
	autoplay: false,
	dots: true,
	draggable: false,
	infinite: false,
	slidesToShow: 2,
	slidesToScroll: 2,
	mobileFirst: true,
	rows: 1,
	responsive: [
		{
			breakpoint: 810,
			settings: 'unslick'
		}
	]
});

/**
 * Slick Carousel for news items on related posts for mobile only
 */

$('.section-related .list-news').slick({
	arrows: false,
	autoplay: false,
	dots: true,
	draggable: false,
	infinite: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	mobileFirst: true,
	rows: 1,
	responsive: [
		{
			breakpoint: 810,
			settings: 'unslick'
		}
	]
});

$('.list-team').slick({
	arrows: true,
	draggable: true,
	infinite: true,
	mobileFirst: true,
	nextArrow: $('.list-people-nav .next'),
	prevArrow: $('.list-people-nav .prev'),
	rows: 1,
	slidesToScroll: 1,
	slidesToShow: 1,
	responsive: [
		{
			breakpoint: 810,
			settings: 'unslick'
		}
	]
});

$('.list-places-grid').slick({
	arrows: false,
	autoplay: false,
	dots: false,
	draggable: true,
	infinite: false,
	pauseOnHover: false,
	slidesToScroll: 1,
	slidesToShow: 1,
	mobileFirst: true,
	speed: 400,
	variableWidth: false,
	responsive: [
		{
			breakpoint: 810,
			settings: 'unslick'
		}
	]
});

$('.list-places').slick({
	arrows: false,
	autoplay: false,
	dots: false,
	draggable: true,
	infinite: false,
	mobileFirst: true,
	pauseOnHover: false,
	slidesToScroll: 1,
	slidesToShow: 1,
	speed: 400,
	swipe: true,
	variableWidth: false,
	responsive: [
		{
			breakpoint: 810,
			settings: 'unslick'
		}
	]
});



/**
 * Slick Carousel for testimonials items on homepage
 */

$('.section-testimonials .list-testimonials').slick({
	arrows: false,
	autoplay: true,
	autoplaySpeed: 6000,
	dots: true,
	draggable: true,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	rows: 1
});

/**
 * Scroll down 1 section when clicking arrow on home arrow
 */

$('.footer-main .sub-back-to-top').on('click', function (ev) {
	ev.preventDefault();

	$('html, body').animate({ scrollTop: 0 }, 1000, 'swing');
});
